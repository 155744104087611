import * as React from "react";
import { graphql } from 'gatsby';
import { Seo } from "../components/atoms";
import { LayoutComponent } from "../components/organisms";
import { PagingComponent, PostComponent } from "../components/molecules";

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          canonical
          tags
          publishedAt(formatString: "YYYY-MM-DD")
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const postComponents = posts.map(({ node }, idx) => {
    return <PostComponent key={idx}
      title={node.title}
      canonical={node.canonical}
      publishedAt={node.publishedAt}
      tags={node.tags} />
  })
  const { currentPage, numPages } = pageContext
  const prevPath = currentPage - 1 === 1 ? "/" : `/pages/${currentPage - 1}`
  const nextPath = `/pages/${currentPage + 1}`

  return (
    <LayoutComponent>
      {postComponents}
      <PagingComponent currentPage={currentPage} nextPath={nextPath} prevPath={prevPath} totalPages={numPages}></PagingComponent>
    </LayoutComponent>
  )
}

export default IndexPage
export const Head = () => (
  <Seo />
)
